import { PromoRateTypeEnum } from "@/helpers/enums";
import { getColumns } from "@/helpers/table";

export const AFFILIATES_TABLE_DEFAULT_COLUMNS = [
  {
    label: "Name",
    field: "name",
    id: "name",
    isHidden: false,
  },
  {
    label: "Business name",
    field: "businessName",
    id: "businessName",
    isHidden: false,
  },
  {
    label: "Active",
    field: (affiliate) => {
      return affiliate.isActive ? "Yes" : "No";
    },
    id: "active",
    isHidden: false,
  },
  {
    label: "Address",
    field: (affiliate) => {
      return [
        affiliate.address.street,
        affiliate.address.city,
        affiliate.address.state,
        affiliate.address.zip,
        affiliate.address.country,
      ]
        .filter(Boolean)
        .join(", ");
    },
    id: "address",
    isHidden: false,
  },
  {
    label: "Commission",
    field: (affiliate) => {
      if (affiliate.commissionType === PromoRateTypeEnum.FIXED) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(Math.abs(affiliate.commission));
      } else if (affiliate.commissionType === PromoRateTypeEnum.PERCENT) {
        return `${affiliate.commission}%`;
      }
      return affiliate.commission;
    },
    id: "commission",
    isHidden: false,
  },
];

export const getAffiliatesTableColumns = (columns) => {
  return getColumns(columns, AFFILIATES_TABLE_DEFAULT_COLUMNS);
};
