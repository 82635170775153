<template>
  <div class="affiliates-page">
    <BackTitle class="mb-40" @click="goBack">Affiliates</BackTitle>
    <Guard permission="affiliates.create" v-slot="{ isAvailable }">
      <IconButton
        v-if="isAvailable"
        icon="plus"
        isRaised
        class="mb-40"
        @click="goToCreation"
      >
        Add new affiliate
      </IconButton>
    </Guard>
    <div class="affiliates-page__content">
      <Loader v-if="isLoading" size="m" color="primary" class="mx-auto" />
      <div v-else-if="!affiliates.length" class="empty">
        You haven't created affiliates yet
      </div>
      <Table
        v-else
        name="affiliatesTable"
        :columns="tableColumns"
        :rows="affiliates"
        @on-view-click="handleOpenAffiliate"
        has-settings
      />
    </div>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle.vue";
import Guard from "@/components/common/Guard.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Table from "@/components/table/Table.vue";
import { getAffiliatesTableColumns } from "@/helpers/affiliatesTable";

export default {
  name: "AffiliatesPage",
  components: { Table, Guard, BackTitle },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      affiliates: (state) => state.affiliates.list,
      affiliatesTableColumns: (state) =>
        state.users.venueUserJunction?.affiliatesTableColumns,
    }),
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
    tableColumns() {
      return getAffiliatesTableColumns(this.affiliatesTableColumns);
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchAffiliates();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchAffiliates: "affiliates/fetchAffiliates",
      deleteAffiliate: "affiliates/deleteAffiliate",
    }),
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    goToCreation() {
      this.$router.push({
        name: "CreateAffiliate",
      });
    },
    handleOpenAffiliate(id) {
      this.$router.push({
        name: "EditAffiliate",
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.affiliates-page__content {
  display: flex;
  flex-direction: column;
}
</style>
